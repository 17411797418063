<template>
  <div v-if="show" id="contacts" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        :title="'Add ' + itemName"
        @click="add"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
    </div>
    <h3 class="mb-3">{{ itemsName }}</h3>
    <vc-editor-table
      :items="items"
      :item-name="itemName"
      rounded-thumbnails
      @edit="edit"
      @add="add"
      @sort="sort"
      @delete="deleteItem"
    />

    <b-modal
      id="contact-item-modal"
      :title="modalModeLabel"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      no-fade
      centered
      @ok="save"
    >
      <image-field
        title="Photo"
        :src="modalThumbSrc"
        @remove="modalThumbSrc = undefined"
        @uploaded="modalThumbSrc = $event.webUrl"
        kind="contact"
        :vendor-key="vendor.key"
        rounded
      />

      <b-row>
        <b-col cols="12" sm="6">
          <vc-text-field-limit
            label="First Name"
            v-model="modalFirstName"
            :editing="true"
            :max-length="40"
            compact
            required
          />
        </b-col>
        <b-col cols="12" sm="6">
          <vc-text-field-limit
            label="Last Name"
            v-model="modalLastName"
            :editing="true"
            :max-length="40"
            compact
            required
          />
        </b-col>
        <b-col cols="12">
          <vc-text-field-limit
            label="Title"
            v-model="modalTitle"
            :editing="true"
            :max-length="50"
          />
        </b-col>
        <b-col cols="12">
          <vc-text-field-limit
            label="Phone"
            v-model="modalPhone"
            :editing="true"
            :max-length="20"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group label="Email">
            <b-input type="email" v-model="modalEmail" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Enumerable from "linq";
import ImageField from "./ImageField.vue";

import isEmail from "validator/es/lib/isEmail";
import isPhone from "validator/es/lib/isMobilePhone";

export default {
  name: "ContactEditor",
  components: { ImageField },
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      selectedItem: undefined,
      modalFirstName: undefined,
      modalLastName: undefined,
      modalThumbSrc: undefined,
      modalTitle: undefined,
      modalPhone: undefined,
      modalEmail: undefined
    };
  },
  computed: {
    itemName() {
      return "Contact";
    },
    itemsName() {
      return `${this.itemName}s`;
    },
    items() {
      return Enumerable.from(this.vendor?.contacts)
        .select(i => {
          return {
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
            desc: i.title,
            thumbSrc: i.headshotSrc,
            item: i
          };
        })
        .toArray();
    },
    modalModeLabel() {
      return `${!this.selectedItem ? "Add" : "Edit"} Contact`;
    },
    selectedItemIndex() {
      return this.items.findIndex(i => i.item === this.selectedItem);
    }
  },
  watch: {
    selectedItem() {
      this.modalFirstName = this.selectedItem?.firstName;
      this.modalLastName = this.selectedItem?.lastName;
      this.modalThumbSrc = this.selectedItem?.headshotSrc;
      this.modalTitle = this.selectedItem?.title;
      this.modalPhone = this.selectedItem?.phone;
      this.modalEmail = this.selectedItem?.email;
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#contacts",
        title: this.itemsName
      });
    }
  },

  methods: {
    add() {
      this.selectedItem = undefined;
      this.$bvModal.show("contact-item-modal");
    },
    edit(i) {
      this.selectedItem = i.item;
      this.$bvModal.show("contact-item-modal");
    },
    sort(d) {
      const data = {
        contactsSort: d
      };
      this.pushChange(
        data,
        d => {
          this.$emit("sorted", d);
        },
        e => {
          this.$emit("sort-error", e);
        }
      );
    },
    save(e) {
      if (!this.modalFirstName?.trim() || !this.modalLastName?.trim()) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a first and last name."
        });
        return;
      }

      if (this.modalEmail?.trim() && !isEmail(this.modalEmail.trim())) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a valid email address."
        });
        return;
      }

      if (this.modalPhone?.trim() && !isPhone(this.modalPhone.trim())) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a valid phone number."
        });
        return;
      }

      const data = {
        contact: {
          firstName: this.modalFirstName?.trim(),
          lastName: this.modalLastName?.trim(),
          headshotSrc: this.modalThumbSrc,
          title: this.modalTitle?.trim(),
          phone: this.modalPhone?.trim(),
          email: this.modalEmail?.trim()
        },
        contactIndex: this.selectedItemIndex
      };

      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.modalFirstName = this.modalLastName = this.modalThumbSrc = this.modalTitle = this.modalPhone = this.modalEmail = undefined;
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    deleteItem(i) {
      this.selectedItem = i.item;

      const data = {
        contactDelete: this.selectedItemIndex
      };
      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.$emit("deleted", d);
        },
        e => {
          this.$emit("delete-error", e);
        }
      );
    },
    pushChange(data, s, e) {
      this.$store
        .dispatch("pushVendorChanges", { vendorKey: this.vendor?.key, ...data })
        .then(s, e);
    }
  }
};
</script>