<template>
  <div id="social" class="platter mb-4 p-3" :data-editing="editing">
    <div class="actions float-right">
      <b-button
        v-if="!editing"
        size="sm"
        variant="outline-primary"
        @click="editing = true"
        v-b-tooltip.hover.v-dark
        title="Edit"
      >
        <font-awesome-icon icon="pencil-alt" fixed-width />
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="save"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="cancel"
      >
        Cancel
      </b-button>
    </div>
    <h3 class="mb-3">Social Media</h3>
    <b-row cols="1" class="p-1">
      <b-col v-if="editing || facebookUrl">
        <b-form-group label="Facebook">
          <template #label>
            <font-awesome-icon :icon="['fab', 'facebook']" fixed-width />
            Facebook
          </template>
          <b-input
            type="url"
            v-if="editing"
            v-model="facebookUrl"
            placeholder="https://www.facebook.com/my_company"
          />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ facebookUrl }}
          </span>
          <template v-if="!editing">
            <a
              :href="facebookUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open Facebook"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </b-form-group>
      </b-col>
      <b-col v-if="editing || twitterUrl">
        <b-form-group label="Twitter">
          <template #label>
            <font-awesome-icon :icon="['fab', 'twitter']" fixed-width />
            Twitter
          </template>
          <b-input
            type="url"
            v-if="editing"
            v-model="twitterUrl"
            placeholder="https://twitter.com/my_company_handle"
          />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ twitterUrl }}
          </span>
          <template v-if="!editing">
            <a
              :href="twitterUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open Twitter"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </b-form-group>
      </b-col>
      <b-col v-if="editing || instagramUrl">
        <b-form-group label="Instagram">
          <template #label>
            <font-awesome-icon :icon="['fab', 'instagram']" fixed-width />
            Instagram
          </template>
          <b-input
            type="url"
            v-if="editing"
            v-model="instagramUrl"
            placeholder="https://www.instagram.com/my_company"
          />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ instagramUrl }}
          </span>
          <template v-if="!editing">
            <a
              :href="instagramUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open Instagram"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </b-form-group>
      </b-col>
      <b-col v-if="editing || linkedInUrl">
        <b-form-group label="LinkedIn">
          <template #label>
            <font-awesome-icon :icon="['fab', 'linkedin']" fixed-width />
            LinkedIn
          </template>
          <b-input
            type="url"
            v-if="editing"
            v-model="linkedInUrl"
            placeholder="https://www.linkedin.com/company/my_company"
          />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ linkedInUrl }}
          </span>
          <template v-if="!editing">
            <a
              :href="linkedInUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open LinkedIn"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </b-form-group>
      </b-col>
      <b-col v-if="editing || youTubeUrl">
        <b-form-group label="LinkedIn">
          <template #label>
            <font-awesome-icon :icon="['fab', 'youtube']" fixed-width />
            YouTube
          </template>
          <b-input
            type="url"
            v-if="editing"
            v-model="youTubeUrl"
            placeholder="https://www.youtube.com/user/my_company"
          />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ youTubeUrl }}
          </span>
          <template v-if="!editing">
            <a
              :href="youTubeUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open YouTube"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="!hasSocialMedia && !editing" class="text-center">
      <b-button variant="primary" @click="editing = true">
        <strong>Add Social Media Links</strong>
      </b-button>
    </div>
  </div>
</template>

<script>
import isURL from "validator/es/lib/isURL";

export default {
  name: "SocialMediaEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      editing: false,
      social: undefined
    };
  },
  computed: {
    hasSocialMedia() {
      //TODO: Make more flexible
      return (
        this.facebookUrl ||
        this.twitterUrl ||
        this.instagramUrl ||
        this.linkedInUrl ||
        this.youTubeUrl
      );
    },
    youTubeUrl: {
      get() {
        return this.social?.youtube;
      },
      set(val) {
        this.social.youtube = val;
      }
    },
    facebookUrl: {
      get() {
        return this.social?.facebook;
      },
      set(val) {
        this.social.facebook = val;
      }
    },
    twitterUrl: {
      get() {
        return this.social?.twitter;
      },
      set(val) {
        this.social.twitter = val;
      }
    },
    instagramUrl: {
      get() {
        return this.social?.instagram;
      },
      set(val) {
        this.social.instagram = val;
      }
    },
    linkedInUrl: {
      get() {
        return this.social?.linkedin;
      },
      set(val) {
        this.social.linkedin = val;
      }
    }
  },
  mounted() {
    this.$emit("add-nav", {
      href: "#social",
      title: "Social Media"
    });
    this.updateLocalData();
  },
  watch: {
    vendor: "updateLocalData"
  },
  methods: {
    updateLocalData() {
      this.social = { ...this.vendor?.social } ?? {};
    },
    save() {
      const fieldErrors = this.fieldErrors();
      if (fieldErrors.length > 0) {
        this.$emit("save-error", {
          message:
            "The following social network fields do not have valid URLs: " +
            fieldErrors.join(", ")
        });
        return;
      }

      const data = {
        vendorKey: this.vendor?.key,
        social: this.social
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.$emit("saved", d);
          this.editing = false;
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    cancel() {
      this.updateLocalData();
      this.editing = false;
    },
    fieldErrors() {
      let errors = [];
      if (!this.isFieldValid("facebook")) errors.push("Facebook");
      if (!this.isFieldValid("twitter")) errors.push("Twitter");
      if (!this.isFieldValid("instagram")) errors.push("Instagram");
      if (!this.isFieldValid("linkedin")) errors.push("LinkedIn");
      if (!this.isFieldValid("youtube")) errors.push("YouTube");
      return errors;
    },
    isFieldValid(field) {
      if (!this.social[field]?.trim()) return true;

      this.social[field] = this.social[field].trim();

      const urlOptions = {
        require_protocol: true
      };

      let url = this.social[field];

      let isValid = isURL(url, urlOptions);

      if (isValid) return true;

      url = "http://" + url;

      isValid = isURL(url, urlOptions);

      if (!isValid) return false;

      this.social[field] = url;
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
span.url {
  word-wrap: break-word;
}
</style>