<template>
  <div id="vendor-admin-nav">
    <div
      v-if="$store.state.adminVendors && $store.state.adminVendors.length > 1"
      class="text-center mb-2"
    >
      <b-button variant="link" pill to="/admin/" size="sm">
        <font-awesome-icon icon="arrow-left" fixed-width />
        Vendor Administration
      </b-button>
    </div>

    <h1 class="text-center">
      <strong v-if="vendor">{{ vendor.name }}</strong>
    </h1>

    <b-card
      v-if="!vendor.active"
      class="bg-danger text-light mb-3"
      body-class="p-2 text-center"
    >
      <strong>This vendor is not currently active.</strong>
    </b-card>

    <div class="text-center mt-3">
      <b-button-group>
        <b-button
          v-if="vendor.canEdit"
          :to="'/admin/' + vendor.key + '/editor'"
          :variant="active == 'Editor' ? 'primary' : 'outline-primary'"
        >
          <font-awesome-icon icon="pencil-alt" fixed-width />
          Editor
        </b-button>
        <b-button
          v-if="vendor.canReport"
          :to="'/admin/' + vendor.key + '/reports'"
          :variant="active == 'Reports' ? 'primary' : 'outline-primary'"
        >
          <font-awesome-icon icon="chart-pie" fixed-width />
          Reports
        </b-button>
        <b-button
          v-if="vendor.isOwner"
          :to="'/admin/' + vendor.key + '/users'"
          :variant="active == 'Users' ? 'primary' : 'outline-primary'"
        >
          <font-awesome-icon icon="users" fixed-width />
          Users
        </b-button>
        <!-- <b-button
          :to="'/admin/' + vendor.key + '/subscription'"
          :variant="active == 'Subscription' ? 'primary' : 'outline-primary'"
        >
          <font-awesome-icon icon="calendar-alt" fixed-width />
          Subscription
        </b-button> -->
      </b-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "VendorAdminNav",
  props: {
    vendor: { type: Object },
    active: { type: String }
  }
};
</script>

<style scoped lang="scss">
.btn-group {
  border-radius: 20px;
}
.btn {
  font-weight: bold;
  border-radius: 20px;
  border-width: 2px;
}
</style>