<template>
  <div class="mt-4">
    <div class="platter text-center mb-4">
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="8">
          <div>
            <h3>
              <strong>Basic Subscription (Year)</strong>
            </h3>
            <h5>$3,600/year</h5>
            <h5>Renews On: January 1, 2022</h5>

            <h6
              class="text-muted d-flex align-items-center justify-content-center"
            >
              Payment: XXXX-XXXX-1234
              <font-awesome-icon
                :icon="['fab', 'cc-mastercard']"
                size="2x"
                class="ml-2"
              />
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <b-button variant="primary" block v-b-modal.subs>
            Change Subscription
          </b-button>

          <b-button variant="primary" block>Change Payment Method</b-button>

          <b-button variant="link" block class="text-muted"
            >Cancel Subscription</b-button
          >
        </b-col>
      </b-row>
    </div>

    <div class="platter">
      <h4 class="mb-3 text-center text-muted">
        <strong>Transaction History</strong>
      </h4>
      <b-table-simple>
        <tbody>
          <tr>
            <td>Basic Subscription (Year)</td>
            <td>January 1, 2021</td>
            <td>$3,600</td>
          </tr>
        </tbody>
      </b-table-simple>
    </div>

    <b-modal
      no-fade
      id="subs"
      size="lg"
      title="Change Subscription"
      cancel-variant="link"
      hide-header
      ok-title="Change Subscription"
      :ok-disabled="newSubscriptionType === currentSubscriptionType"
      cancel-title="Don't Change"
    >
      <b-row>
        <b-col cols="12" md="4">
          <h4><strong>Basic</strong> <small>Subscription</small></h4>
        </b-col>
        <b-col cols="12" md="8">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            finibus, purus quis vehicula hendrerit, diam nisi viverra nibh, quis
            malesuada felis metus in ligula. Aliquam feugiat nunc quam. Mauris
            ut leo lobortis, ultricies est vitae, consequat arcu. Donec pulvinar
            suscipit odio ut mattis.
          </p>

          <b-row cols="1" cols-lg="2">
            <b-col>
              <b-button
                block
                :variant="
                  newSubscriptionType == 1 ? 'primary' : 'outline-primary'
                "
                class="mb-2"
                @click="newSubscriptionType = 1"
              >
                Yearly: $3,600
              </b-button>
            </b-col>
            <b-col>
              <b-button
                block
                :variant="
                  newSubscriptionType == 2 ? 'primary' : 'outline-primary'
                "
                @click="newSubscriptionType = 2"
                >Monthly: $300</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <br />

      <b-row>
        <b-col cols="12" md="4">
          <h4><strong>Premium</strong> <small>Subscription</small></h4>
        </b-col>
        <b-col cols="12" md="8">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            finibus, purus quis vehicula hendrerit, diam nisi viverra nibh, quis
            malesuada felis metus in ligula. Aliquam feugiat nunc quam. Mauris
            ut leo lobortis, ultricies est vitae, consequat arcu. Donec pulvinar
            suscipit odio ut mattis.
          </p>

          <b-row cols="1" cols-lg="2">
            <b-col>
              <b-button
                block
                :variant="
                  newSubscriptionType == 3 ? 'primary' : 'outline-primary'
                "
                class="mb-2"
                @click="newSubscriptionType = 3"
              >
                Yearly: $X,XXX
              </b-button>
            </b-col>
            <b-col>
              <b-button
                block
                :variant="
                  newSubscriptionType == 4 ? 'primary' : 'outline-primary'
                "
                class="mb-2"
                @click="newSubscriptionType = 4"
                >Monthly: $XXX</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr />

      Selected Subscription:
      <b-select v-model="newSubscriptionType">
        <b-select-option-group label="Basic">
          <b-select-option :value="1">
            Basic Subscription (Yearly) - $3,600
          </b-select-option>
          <b-select-option :value="2">
            Basic Subscription (Monthly) - $300
          </b-select-option>
        </b-select-option-group>
        <b-select-option-group label="Premium">
          <b-select-option :value="3">
            Premium Subscription (Yearly) - $X,XXX
          </b-select-option>
          <b-select-option :value="4">
            Premium Subscription (Monthly) - $XXX
          </b-select-option>
        </b-select-option-group>
      </b-select>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "VendorSubscription",
  data() {
    return {
      newSubscriptionType: 1
    };
  },
  computed: {
    currentSubscriptionType() {
      return 1;
    }
  }
};
</script>

<style>
</style>