<template>
  <div
    v-if="show"
    id="category"
    class="platter mb-4 p-3"
    :data-editing="editing"
  >
    <div class="actions float-right">
      <b-button
        v-if="!editing"
        size="sm"
        variant="outline-primary"
        @click="edit"
        v-b-tooltip.hover.v-dark
        title="Edit Categories"
      >
        <font-awesome-icon icon="pencil-alt" fixed-width />
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="save"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="editing = false"
      >
        Cancel
      </b-button>
    </div>
    <h3 class="mb-3">
      Categories

      <b-badge variant="light" class="text-muted">
        <font-awesome-icon icon="lock-alt" /> System Admins Only
      </b-badge>
    </h3>

    <div
      v-if="!editing && !activeCategories.length"
      class="text-center text-muted"
    >
      <em>No categories selected.</em>
    </div>
    <ul v-if="!editing" class="list-inline">
      <li
        v-for="c in activeCategories"
        :key="c.key"
        class="list-inline-item mr-4"
      >
        <font-awesome-icon
          :icon="[c.iconStyle ? c.iconStyle : 'fas', c.icon]"
          class="hamburger mr-1"
          fixed-width
        />
        {{ c.name }}
      </li>
    </ul>
    <b-row cols="2" cols-sm="3" v-else>
      <b-col v-for="c in allCategories" :key="c.key" class="mb-2">
        <b-check
          switch
          :checked="c.selected"
          @change="toggleSelected(c.key, $event)"
        >
          <font-awesome-icon
            :icon="[c.iconStyle ? c.iconStyle : 'fas', c.icon]"
            class="hamburger mr-1"
            fixed-width
          />
          {{ c.name }}
        </b-check>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CategoryEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      editing: false,
      selected: []
    };
  },
  computed: {
    show() {
      return this.$store.state.currentUser.systemAdmin;
    },
    activeCategories() {
      return this.allCategories.filter(c => c.selected);
    },
    allCategories() {
      return this.$store.state.categories.map(cc => {
        return {
          ...cc,
          selected: !!(this.vendor.categories ?? []).find(
            c => c.toLowerCase() === cc.key.toLowerCase()
          )
        };
      });
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#category",
        title: "Categories"
      });
    }
  },
  methods: {
    edit() {
      this.selected = this.activeCategories.map(i => i.key);
      this.editing = true;
    },
    save() {
      const data = {
        vendorKey: this.vendor?.key,
        categories: this.selected
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.$emit("saved", d);
          this.editing = false;
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    toggleSelected(key, checked) {
      if (checked) {
        this.selected.push(key);
      } else {
        this.selected = this.selected.filter(i => i !== key);
      }
    }
  }
};
</script>
