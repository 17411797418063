<template>
  <transition name="custom" enter-active-class="fadeIn">
    <div v-if="data" class="d-flex trend mb-4 mb-sm-0" :data-size="size">
      <div class="trend-legend">
        <h5 class="mb-1 mr-1">{{ title }}</h5>
        <h3>
          {{ totalDisplay }}
          <b-badge v-if="delta" :variant="isImproved ? 'success' : 'danger'">
            {{ deltaDisplay }}
          </b-badge>
        </h3>
      </div>
      <trend
        :data="trendData"
        :gradient="trendGradient"
        smooth
        auto-draw
        :auto-draw-duration="600"
        :stroke-width="2"
      />
    </div>
    <vc-loading v-else>Loading {{ title }}...</vc-loading>
  </transition>
</template>

<script>
import Trend from "vuetrend";
import Enumerable from "linq";
import _ from "lodash";

export default {
  name: "Trendline",
  components: { Trend },
  props: {
    title: { type: String, required: true },
    data: { type: Array },
    delta: { type: Number },
    dataField: { type: String, default: "views" },
    size: { type: String },
    total: { type: Number }
  },
  computed: {
    totalDisplay() {
      if (this.total) return this.total;
      return Enumerable.from(this.trendData).sum(d => d);
    },
    deltaDisplay() {
      if (!this.delta) return undefined;
      return `${(this.delta >= 0 ? "+" : "") +
        Math.round(this.delta * 100, 0)}%`;
    },
    isImproved() {
      if (!this.delta) return undefined;
      return this.delta > 0;
    },
    trendData() {
      return Enumerable.from(this.data)
        .select(i => {
          if (_.isNumber(i)) return i;
          return i[this.dataField];
        })
        .toArray();
    },
    trendGradient() {
      if (this.isImproved === undefined)
        return ["#9E9E9E", "#424242", "#212121"];
      if (this.isImproved) return ["#81C784", "#66BB6A", "#7CB342", "#558B2F"];
      return ["#ef5350", "#e53935", "#c62828", "#b71c1c"];
    }
  }
};
</script>

<style scoped lang="scss">
.trend .badge {
  font-size: 0.45em;
}
.trend-legend {
  flex-shrink: 0;
}
[data-size="sm"] {
  h3 {
    font-size: 1.3em;
  }
  h5 {
    font-size: 0.95em;
  }
}
</style>