<template>
  <div v-if="show" id="testimonials" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        :title="'Add ' + itemName"
        @click="add"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
    </div>
    <h3 class="mb-3">{{ itemsName }}</h3>
    <vc-editor-table
      :items="items"
      :item-name="itemName"
      @edit="edit"
      @add="add"
      @delete="deleteItem"
      @sort="sort"
    />

    <b-modal
      id="testimonial-item-modal"
      :title="modalModeLabel"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      no-fade
      centered
      @ok="save"
    >
      <vc-text-field-limit
        :label="itemName"
        v-model="modalTestimonial"
        :editing="true"
        :max-length="275"
        :rows="4"
        :max-rows="6"
        always-show-remaining
        required
      />

      <vc-text-field-limit
        label="Author"
        v-model="modalAuthor"
        :editing="true"
        :max-length="100"
        always-show-remaining
        required
      />
    </b-modal>
  </div>
</template>

<script>
import Enumerable from "linq";

export default {
  name: "TestimonialsEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      selectedItem: undefined,
      modalTestimonial: undefined,
      modalAuthor: undefined
    };
  },
  computed: {
    itemName() {
      return "Testimonial";
    },
    itemsName() {
      return `${this.itemName}s`;
    },
    modalModeLabel() {
      return `${!this.selectedItem ? "Add" : "Edit"} ${this.itemName}`;
    },
    items() {
      return Enumerable.from(this.vendor?.testimonials)
        .select(i => {
          return {
            title: i.copy,
            desc: i.author,
            item: i
          };
        })
        .toArray();
    },
    selectedItemIndex() {
      return this.items.findIndex(i => i.item === this.selectedItem);
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#testimonials",
        title: this.itemsName
      });
    }
  },
  watch: {
    selectedItem() {
      this.modalTestimonial = this.selectedItem?.copy;
      this.modalAuthor = this.selectedItem?.author;
    }
  },
  methods: {
    add() {
      this.selectedItem = undefined;
      this.$bvModal.show("testimonial-item-modal");
    },
    edit(i) {
      this.selectedItem = i.item;
      this.$bvModal.show("testimonial-item-modal");
    },
    save(e) {
      if (!this.modalTestimonial?.trim()) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a testimonial."
        });
        return;
      }

      if (!this.modalAuthor?.trim()) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a testimonial author."
        });
        return;
      }

      const data = {
        testimonial: {
          author: this.modalAuthor?.trim(),
          copy: this.modalTestimonial?.trim()
        },
        testimonialIndex: this.selectedItemIndex
      };

      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.modalAuthor = this.modalTestimonial = undefined;
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    deleteItem(i) {
      this.selectedItem = i.item;

      const data = {
        testimonialDelete: this.selectedItemIndex
      };
      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.$emit("deleted", d);
        },
        e => {
          this.$emit("delete-error", e);
        }
      );
    },
    sort(d) {
      const data = {
        testimonialSort: d
      };
      this.pushChange(
        data,
        d => {
          this.$emit("sorted", d);
        },
        e => {
          this.$emit("sort-error", e);
        }
      );
    },
    pushChange(data, s, e) {
      this.$store
        .dispatch("pushVendorChanges", { vendorKey: this.vendor?.key, ...data })
        .then(s, e);
    }
  }
};
</script>