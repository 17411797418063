<template>
  <div id="vendor-reports">
    <div class="text-center">
      <b-dropdown
        id="dropdown-1"
        :text="range"
        class="m-3"
        variant="primary"
        v-model="range"
        v-if="earliestDaysBack > 30"
      >
        <b-dropdown-item @click="range = 'Last 30 Days'">
          Last 30 Days
        </b-dropdown-item>
        <b-dropdown-item @click="range = 'Last 60 Days'">
          Last 60 Days
        </b-dropdown-item>
        <b-dropdown-item
          v-if="earliestDaysBack > 60"
          @click="range = 'Last 90 Days'"
        >
          Last 90 Days
        </b-dropdown-item>
        <b-dropdown-item
          v-if="earliestDaysBack > 90"
          @click="range = 'Last Year'"
        >
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-row v-if="loaded && !errorMessage" id="widgets" class="mt-3">
      <b-col
        cols="12"
        v-if="earliestDaysBack < 30 || daysBack > earliestDaysBack"
      >
        <p class="text-center text-muted">
          <strong>Showing data starting on {{ earliestDateFormatted }}</strong>
        </p>
      </b-col>
      <b-col cols="12">
        <div class="platter mb-4">
          <b-row cols="1" cols-sm="2">
            <b-col>
              <trendline
                title="Profile Views"
                :data="analytics.totalViewsByDay"
                :total="analytics.totalViews"
                :delta="0"
              />
            </b-col>
            <b-col>
              <trendline
                title="Unique Members"
                :data="analytics.uniqueViewsByDay"
                :total="analytics.uniqueViews"
                :delta="0"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" sm="6">
        <vendor-report-widget
          class="mb-4"
          title="Engagement by Age Group"
          :data="analytics.ageGroupViews"
        />
      </b-col>
      <b-col cols="12" sm="6">
        <vendor-report-widget
          class="mb-4"
          title="Engagement by Practice Type"
          :data="analytics.practiceTypeViews"
        />
      </b-col>
      <b-col cols="12">
        <vendor-report-widget
          class="mb-4"
          title="Visits by Weekday"
          :data="analytics.visitsByWeekday"
          chart-type="BarChart"
          hide-legend
          single-color
        />
      </b-col>
      <b-col cols="12" sm="6">
        <vendor-report-widget
          class="mb-4"
          title="Engagement by Practice Demographic"
          :data="analytics.practiceDemoViews"
        />
      </b-col>
      <b-col cols="12" sm="6">
        <vendor-report-widget
          class="mb-4"
          title="Engagement by Gender"
          :data="analytics.genderViews"
        />
      </b-col>
    </b-row>
    <vc-loading v-else-if="errorMessage" has-error class="m-3">
      {{ errorMessage }}
    </vc-loading>
    <vc-loading v-else class="m-3">Loading Report Data...</vc-loading>
  </div>
</template>

<script>
import VendorReportWidget from "./VendorReportWidget";
import Trendline from "./Trendline.vue";

export default {
  name: "VendorReports",
  components: {
    VendorReportWidget,
    Trendline
  },
  props: {
    vendor: { type: Object, required: true }
  },
  data() {
    return {
      range: "Last 30 Days",
      loaded: false,
      errorMessage: undefined,
      analytics: {},
      earliestDate: undefined
    };
  },
  computed: {
    earliestDateFormatted() {
      return this.earliestDate?.toLocaleString(this.$luxonDateTime.DATE_FULL);
    },
    earliestDaysBack() {
      if (!this.earliestDate) return 0;
      return this.$luxonInterval
        .fromDateTimes(this.earliestDate, this.$luxonDateTime.utc())
        .count("day");
    },
    daysBack() {
      switch (this.range) {
        case "Last 60 Days":
          return 60;
        case "Last 90 Days":
          return 90;
        case "Last Year":
          return 365;
        default:
          return 30;
      }
    }
  },
  watch: {
    range() {
      this.fetchData();
    },
    vendor() {
      this.range = "Last 30 Days";
      this.fetchEarliestDate();
      this.fetchData();
    }
  },
  mounted() {
    this.fetchEarliestDate();
    this.fetchData();
  },
  methods: {
    fetchEarliestDate() {
      this.$store
        .dispatch("fetchVendorAnalyticsEarliestDate", this.vendor.key)
        .then(d => (this.earliestDate = this.$luxonDateTime.fromISO(d)));
    },
    fetchData() {
      this.loaded = false;
      this.errorMessage = undefined;
      this.loadedCount = 0;

      this.$store
        .dispatch("fetchVendorAnalytics", {
          vendorKey: this.vendor.key,
          daysBack: this.daysBack
        })
        .then(
          d => {
            this.analytics = d;
          },
          e => (this.errorMessage = e.message)
        )
        .finally(() => (this.loaded = true));
    }
  }
};
</script>

<style scoped lang="scss">
#widgets ::v-deep {
  .btn-outline-primary,
  .btn-outline-danger {
    border: 0;
  }

  div.actions {
    border-radius: 15px;

    .btn {
      border-radius: 15px;
    }
  }
}
</style>