<template>
  <div class="platter mb-4 light p-2">
    <b-row class="align-items-center">
      <b-col cols="3" class="text-center">
        <h5 class="mb-0"><strong>Profile Score</strong></h5>
        <h1 class="mb-0">
          <strong>{{ score }}%</strong>
        </h1>
      </b-col>
      <b-col>
        <p v-if="incompleteActions.length > 0" class="accent mb-1">
          <small>
            <strong>
              Add materials to improve your profile:
            </strong>
          </small>
        </p>
        <ul class="list-inline">
          <li
            v-for="a in incompleteActions"
            :key="a.name"
            class="list-inline-item"
          >
            <b-button
              size="sm"
              variant="danger"
              v-scroll-to="a.href"
              class="mb-1"
            >
              <font-awesome-icon
                v-if="a.complete"
                icon="check"
                class="mr-1"
                fixed-width
              />
              <strong>{{ a.name }}</strong>
            </b-button>
          </li>
        </ul>

        <p v-if="completeActions.length > 0" class="accent mb-1">
          <small>
            <strong>
              Materials already added:
            </strong>
          </small>
        </p>

        <ul class="list-inline mb-0">
          <li
            v-for="a in completeActions"
            :key="a.name"
            class="list-inline-item"
          >
            <b-button
              size="sm"
              variant="light"
              v-scroll-to="a.href"
              class="mb-1"
            >
              <font-awesome-icon icon="check" class="mr-1" fixed-width />
              {{ a.name }}
            </b-button>
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ProfileScore",
  props: {
    vendor: { type: Object }
  },
  computed: {
    actions() {
      let a = [
        { href: "#basic", name: "Website URL", complete: this.vendor.website },
        { href: "#logo", name: "Logo", complete: this.vendor.logoSrc },
        {
          href: "#desc",
          name: "Short Description",
          complete: this.vendor.shortDesc
        },
        {
          href: "#desc",
          name: "Long Description",
          complete: this.vendor.shortDesc
        },
        {
          href: "#social",
          name: "Social Media",
          complete: Object.keys(this.vendor.social ?? {}).length > 0
        },
        {
          href: "#videos",
          name: "Video",
          complete: this.vendor.videos?.length ?? 0 > 0
        },
        {
          href: "#products",
          name: "Product",
          complete: this.vendor.products?.length ?? 0 > 0
        },
        {
          href: "#services",
          name: "Service",
          complete: this.vendor.services?.length ?? 0 > 0
        },
        {
          href: "#promos",
          name: "Promotion",
          complete: this.vendor.promos?.length ?? 0 > 0
        },
        {
          href: "#testimonials",
          name: "Testimonial",
          complete: this.vendor.testimonials?.length ?? 0 > 0
        },
        {
          href: "#contacts",
          name: "Contact",
          complete: this.vendor.contacts?.length ?? 0 > 0
        }
      ];

      return a.sort((i, j) => i.complete > j.complete);
    },
    incompleteActions() {
      return this.actions.filter(a => !a.complete);
    },
    completeActions() {
      return this.actions.filter(a => a.complete);
    },
    score() {
      const actionsCount = this.actions.length;
      const completeActionsCount = this.completeActions.length;
      return Math.round((completeActionsCount / actionsCount) * 100);
    }
  }
};
</script>
