<template>
  <div id="vendor-admin">
    <vc-app-header />

    <template v-if="vendorKey">
      <template v-if="vendor">
        <vendor-admin-nav :active="view" :vendor="vendor" />

        <div class="content">
          <vendor-editor
            v-if="view === 'Editor'"
            :vendor="vendor"
            @edited="edited"
          />
          <vendor-reports v-else-if="view === 'Reports'" :vendor="vendor" />
          <vendor-subscription
            v-else-if="view === 'Subscription'"
            :vendor="vendor"
          />
          <vendor-users v-else-if="view === 'Users'" :vendor="vendor" />
        </div>
      </template>
      <vc-loading v-else-if="errorMessage" has-error>
        {{ errorMessage }}
      </vc-loading>
      <vc-loading v-else>Loading Vendor...</vc-loading>
    </template>
    <template v-else>
      <template v-if="loaded && !errorMessage">
        <h1 class="text-center mb-5">
          <strong>Vendor Administration</strong>
        </h1>

        <b-row class="mb-3">
          <b-col class="mb-3 mb-sm-0">
            <b-input
              type="search"
              placeholder="Search Vendors"
              v-model="query"
            />
          </b-col>
          <b-col
            v-if="isSystemAdmin"
            sm="3"
            md="3"
            class="text-center mb-3 mb-sm-0"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="viewMode"
              :options="['Active', 'Inactive', 'All']"
              name="radios-btn-default"
              buttons
              button-variant="light"
            ></b-form-radio-group>
          </b-col>
          <b-col v-if="isSystemAdmin" sm="3" md="3">
            <b-button block variant="dark" v-b-modal.add-vendor-modal
              ><strong>Add Vendor</strong></b-button
            >
          </b-col>
        </b-row>

        <b-table-simple class="editor-list">
          <tbody>
            <tr v-for="v in filteredVendors" :key="v.key">
              <td class="thumb text-center d-none d-sm-table-cell">
                <b-link :to="'/admin/' + v.key">
                  <b-img-lazy :src="v.logoSrc" v-if="v.logoSrc" />
                </b-link>
              </td>
              <td>
                <h5 class="mb-0">
                  <b-link :to="'/admin/' + v.key">
                    {{ v.name }}
                  </b-link>
                </h5>
              </td>
              <td
                v-if="false"
                :class="'active' + (v.active ? '' : ' text-muted')"
              >
                <b-check
                  switch
                  :checked="v.active"
                  @change="toggleVendorActive(v)"
                  >Active</b-check
                >
              </td>
              <td class="manage text-right">
                <b-button :to="'/admin/' + v.key" variant="primary" size="sm">
                  <strong>Manage</strong>
                </b-button>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
      </template>
      <vc-loading v-else-if="loaded" has-error>{{ errorMessage }}</vc-loading>
      <vc-loading v-if="!loaded">Loading Vendors...</vc-loading>

      <b-modal
        id="add-vendor-modal"
        title="Add New Vendor"
        centered
        no-fade
        cancel-variant="link"
        ok-title="Add Vendor"
        :ok-disabled="!canAddVendor"
        @ok="createVendor"
      >
        <b-form-group label="Vendor Name">
          <b-input v-model="modalVendorName" />
        </b-form-group>

        <b-form-group label="Vendor Key">
          <b-input v-model="modalVendorKey" />
          <p class="mb-0">
            <small class="text-muted">
              Used for the URL of the vendor.
            </small>
          </p>
        </b-form-group>
      </b-modal>
    </template>
  </div>
</template>

<script>
import VendorEditor from "@/components/Vendor/Editor/VendorEditor";
import VendorReports from "@/components/Vendor/Reporting/VendorReports";
import VendorUsers from "@/components/Vendor/Users/VendorUsers";
import VendorAdminNav from "@/components/Vendor/VendorAdminNav";

import VendorSubscription from "../components/Vendor/Subscription/VendorSubscription.vue";

export default {
  name: "VendorAdmin",
  components: {
    VendorAdminNav,
    VendorEditor,
    VendorReports,
    VendorSubscription,
    VendorUsers
  },
  props: {
    vendorKey: { type: String },
    view: { type: String, default: "" }
  },
  computed: {
    filteredVendors() {
      return this.vendors.filter(
        v =>
          v.name.toLowerCase().includes(this.query.toLowerCase()) &&
          (this.viewMode == "All" ||
            (this.viewMode == "Active" && v.active) ||
            (this.viewMode == "Inactive" && !v.active))
      );
    },
    isSystemAdmin() {
      return this.$store.state.currentUser.systemAdmin;
    },
    canAddVendor() {
      return !!(this.modalVendorName && this.modalVendorKey);
    }
  },
  data() {
    return {
      vendor: undefined,
      vendors: [],
      loaded: false,
      query: "",
      viewMode: "Active",
      modalVendorName: "",
      modalVendorKey: "",
      errorMessage: undefined
    };
  },
  watch: {
    vendorKey() {
      this.fetchData();
    },
    vendor() {
      this.updatePageTitle();
    },
    view() {
      this.updatePageTitle();
      this.startView();
    },
    vendors() {
      if ((this.vendors?.length ?? 0) === 1) {
        this.$router.replace("/admin/" + this.vendors[0].key);
      }
    },
    modalVendorName() {
      this.modalVendorKey = this.modalVendorName
        .toLowerCase()
        .replace(/[\W_]+/g, "-");
    },
    modalVendorKey() {
      this.$nextTick(() => {
        this.modalVendorKey = this.modalVendorKey
          .toLowerCase()
          .replace(/[\W_]+/g, "-");
      });
    }
  },
  mounted() {
    this.fetchData();
    this.updatePageTitle();
  },
  methods: {
    fetchData() {
      this.vendors = [];
      this.vendor = undefined;
      this.loaded = false;
      this.errorMessage = undefined;
      if (!this.vendorKey) {
        this.$store
          .dispatch("fetchVendorsForAdmin")
          .then(
            vs => {
              this.vendors = vs;
            },
            e => {
              this.errorMessage = e.message;
            }
          )
          .finally(() => (this.loaded = true));
      } else {
        this.$store
          .dispatch("fetchVendorByKey", this.vendorKey)
          .then(
            v => {
              this.vendor = { ...v };
              this.startView();
            },
            e => {
              this.errorMessage = e.message;
            }
          )
          .finally(() => (this.loaded = true));
      }
    },
    updatePageTitle() {
      if (!this.vendorKey) {
        document.title = `Vendor Admin | ${this.$store.getters.appName}`;
        return;
      }
      document.title = `${this.view} | ${this.vendor?.name} | ${this.$store.getters.appName}`;
    },
    edited(vendorData) {
      //TODO: Handle errors
      this.vendor = vendorData;
    },
    startView() {
      if (!this.view && this.vendor) {
        if (this.vendor.canEdit) {
          this.$router.replace("/admin/" + this.vendor.key + "/editor");
        } else if (this.vendor.canReport) {
          this.$router.replace("/admin/" + this.vendor.key + "/reports");
        }
      }
    },
    toggleVendorActive(vendor) {
      this.$store
        .dispatch("pushVendorChanges", {
          vendorKey: vendor.key,
          active: !vendor.active
        })
        .then(() => {
          vendor.active = !vendor.active;
        });
    },
    createVendor() {
      const data = {
        vendorKey: this.modalVendorKey,
        name: this.modalVendorName
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.modalVendorKey = this.modalVendorName = "";
          this.$router.push("/admin/" + d.key);
        },
        e => {
          this.$bvModal.msgBoxOk(e.message, {
            title: "Error",
            centered: true,
            noFade: true
          });
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
table.editor-list {
  tr {
    height: 80px;
  }
  td {
    vertical-align: middle;
  }
  td.thumb {
    width: 130px;

    img {
      max-height: 50px;
      max-width: 120px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

td.active,
td.manage {
  width: 50px;
}
#vendor-admin .content ::v-deep {
  .btn-outline-primary,
  .btn-outline-danger {
    border: 0;
  }

  div.actions {
    border-radius: 15px;

    .btn {
      border-radius: 15px;
    }
  }
}
</style>