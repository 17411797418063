<template>
  <div
    v-if="show"
    id="alias-paths"
    class="platter mb-4 p-3"
    :data-editing="editing"
  >
    <div class="actions float-right">
      <b-button
        v-if="!editing"
        size="sm"
        variant="outline-primary"
        @click="edit"
        v-b-tooltip.hover.v-dark
        title="Edit Alias Paths"
      >
        <font-awesome-icon icon="pencil-alt" fixed-width />
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="save"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="editing = false"
      >
        Cancel
      </b-button>
    </div>

    <h3 class="mb-3">
      Alias Paths
      <b-badge variant="light" class="text-muted">
        <font-awesome-icon icon="lock-alt" /> System Admins Only
      </b-badge>
    </h3>

    <div v-if="!editing && !aliasPaths.length" class="text-center text-muted">
      <em>No alias paths defined.</em>
    </div>
    <ul v-if="!editing" class="list-inline">
      <li v-for="k in aliasPaths" :key="k" class="list-inline-item mr-1">
        <b-badge variant="primary">{{ k }}</b-badge>
      </li>
    </ul>
    <b-row v-else>
      <b-col>
        <b-form-tags
          autofocus
          v-model="localAliasPaths"
          tag-variant="primary"
          add-button-variant="dark"
          :disabled="!editing"
          :placeholder="editing ? 'Add Alias Path...' : ''"
          separator=", "
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AliasPathEditor",
  props: {
    vendor: { type: Object, required: true }
  },
  data() {
    return {
      editing: false,
      localAliasPaths: []
    };
  },
  computed: {
    show() {
      return this.$store.state.currentUser?.systemAdmin;
    },
    aliasPaths() {
      return this.vendor?.aliasKeys ?? [];
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#alias-paths",
        title: "Alias Paths"
      });
    }
  },
  methods: {
    edit() {
      this.localAliasPaths = this.aliasPaths ? [...this.aliasPaths] : [];
      this.editing = true;
    },
    save() {
      const data = {
        vendorKey: this.vendor?.key,
        aliasKeys: this.localAliasPaths
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.$emit("saved", d);
          this.editing = false;
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    }
  }
};
</script>