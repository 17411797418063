var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"platter"},[_c('transition',{attrs:{"name":"custom","enter-active-class":"fadeIn"}},[(_vm.data)?_c('div',[_c('div',{staticClass:"actions float-right"},[_c('b-button',{attrs:{"size":"sm","variant":_vm.reportMode == 'chart' ? 'primary' : 'outline-primary'},on:{"click":function($event){_vm.reportMode = 'chart'}}},[_c('font-awesome-icon',{attrs:{"icon":"chart-pie","fixed-width":""}})],1),_c('b-button',{attrs:{"size":"sm","variant":_vm.reportMode == 'table' ? 'primary' : 'outline-primary'},on:{"click":function($event){_vm.reportMode = 'table'}}},[_c('font-awesome-icon',{attrs:{"icon":"table","fixed-width":""}})],1)],1),_c('h5',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.title))]),_c('transition',{attrs:{"name":"custom","enter-active-class":"fadeIn"}},[(_vm.reportMode == 'chart')?_c(_vm.chartType,{tag:"component",attrs:{"data":_vm.reportData,"labels":_vm.reportLabels,"background-color":_vm.singleColor
              ? '#0288D1'
              : [
                  '#d32f2f',
                  '#7B1FA2',
                  '#303F9F',
                  '#0288D1',
                  '#00796B',
                  '#689F38',
                  '#FBC02D',
                  '#F57C00'
                ].reverse(),"options":{
            maintainAspectRatio: false,
            legend: !_vm.hideLegend
              ? {
                  position: 'bottom'
                }
              : false,
            scales:
              _vm.chartType == 'BarChart'
                ? {
                    yAxes: [
                      {
                        ticks: {
                          suggestedMin: 0
                        }
                      }
                    ]
                  }
                : undefined
          }}}):_c('b-table-simple',[_c('tbody',_vm._l((_vm.reportLabels),function(l,i){return _c('tr',{key:l},[_c('td',[_vm._v(_vm._s(l))]),_c('td',[_vm._v(_vm._s(_vm.reportData[i]))])])}),0)])],1)],1):_c('vc-loading',[_vm._v("Loading "+_vm._s(_vm.title)+"...")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }