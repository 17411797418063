<template>
  <div v-if="show" id="logo" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        v-if="!upload"
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        title="Upload New"
        @click="upload = true"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
      <b-button
        v-if="!upload"
        size="sm"
        variant="outline-danger"
        @click="deleteLogo"
        v-b-tooltip.hover.v-dark
        title="Delete"
      >
        <font-awesome-icon icon="trash-alt" fixed-width />
      </b-button>
      <b-button
        v-if="upload && uploadedSrc"
        size="sm"
        variant="outline-primary"
        @click="saveNew"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="upload && logoSrc"
        size="sm"
        variant="outline-primary"
        @click="
          upload = false;
          uploadedSrc = undefined;
        "
      >
        Cancel
      </b-button>
    </div>
    <h3 class="mb-3">Logo</h3>

    <div class="text-center">
      <b-img-lazy v-if="!upload" :src="logoSrc" class="view" />

      <div v-if="upload" class="m-4">
        <image-field
          title=""
          kind="logo"
          :vendor-key="vendor.key"
          @uploaded="handleNewImage"
          @remove="uploadedSrc = undefined"
          :src="uploadedSrc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageField from "./ImageField.vue";

export default {
  name: "LogoEditor",
  components: { ImageField },
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      upload: false,
      logoSrc: undefined,
      uploadedSrc: undefined
    };
  },
  watch: {
    vendor: "handleVendor"
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#logo",
        title: "Logo"
      });
    }
    this.handleVendor();
  },
  methods: {
    handleVendor() {
      this.logoSrc = this.vendor?.logoSrc;
      if (!this.logoSrc) this.upload = true;
    },
    deleteLogo() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete the logo?", {
          title: "Delete Logo",
          noFade: true,
          centered: true,
          cancelVariant: "link",
          okTitle: "Delete",
          okVariant: "danger"
        })
        .then(d => {
          if (!d) return;
          this.$store
            .dispatch("pushVendorChanges", {
              vendorKey: this.vendor?.key,
              logoSrc: ""
            })
            .then(
              d => {
                this.$emit("deleted", d);
                this.uploadedSrc = undefined;
                this.upload = false;
              },
              e => {
                this.$emit("delete-error", e);
              }
            );
        });
    },
    handleNewImage(d) {
      this.uploadedSrc = d.webUrl;
    },
    saveNew() {
      this.$store
        .dispatch("pushVendorChanges", {
          vendorKey: this.vendor?.key,
          logoSrc: this.uploadedSrc
        })
        .then(
          d => {
            this.$emit("saved", d);
            this.uploadedSrc = undefined;
            this.upload = false;
          },
          e => {
            this.$emit("save-error", e);
          }
        );
    }
  }
};
</script>

<style scoped lang="scss">
img.view {
  max-width: 70%;
  max-height: 150px;
}
</style>