import { render, staticRenderFns } from "./VendorEditor.vue?vue&type=template&id=049f42fb&scoped=true&"
import script from "./VendorEditor.vue?vue&type=script&lang=js&"
export * from "./VendorEditor.vue?vue&type=script&lang=js&"
import style0 from "./VendorEditor.vue?vue&type=style&index=0&id=049f42fb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049f42fb",
  null
  
)

export default component.exports