<template>
  <div class="platter">
    <transition name="custom" enter-active-class="fadeIn">
      <div v-if="data">
        <div class="actions float-right">
          <b-button
            size="sm"
            :variant="reportMode == 'chart' ? 'primary' : 'outline-primary'"
            @click="reportMode = 'chart'"
          >
            <font-awesome-icon icon="chart-pie" fixed-width />
          </b-button>
          <b-button
            size="sm"
            :variant="reportMode == 'table' ? 'primary' : 'outline-primary'"
            @click="reportMode = 'table'"
          >
            <font-awesome-icon icon="table" fixed-width />
          </b-button>
        </div>

        <h5 class="mb-3">{{ title }}</h5>

        <transition name="custom" enter-active-class="fadeIn">
          <component
            :is="chartType"
            v-if="reportMode == 'chart'"
            :data="reportData"
            :labels="reportLabels"
            :background-color="
              singleColor
                ? '#0288D1'
                : [
                    '#d32f2f',
                    '#7B1FA2',
                    '#303F9F',
                    '#0288D1',
                    '#00796B',
                    '#689F38',
                    '#FBC02D',
                    '#F57C00'
                  ].reverse()
            "
            :options="{
              maintainAspectRatio: false,
              legend: !hideLegend
                ? {
                    position: 'bottom'
                  }
                : false,
              scales:
                chartType == 'BarChart'
                  ? {
                      yAxes: [
                        {
                          ticks: {
                            suggestedMin: 0
                          }
                        }
                      ]
                    }
                  : undefined
            }"
          />
          <b-table-simple v-else>
            <tbody>
              <tr v-for="(l, i) in reportLabels" :key="l">
                <td>{{ l }}</td>
                <td>{{ reportData[i] }}</td>
              </tr>
            </tbody>
          </b-table-simple>
        </transition>
      </div>
      <vc-loading v-else>Loading {{ title }}...</vc-loading>
    </transition>
  </div>
</template>

<script>
const PieChart = () =>
  import(/* webpackChunkName: "pieChart" */ "@/components/Charts/PieChart.vue");
const BarChart = () =>
  import(/* webpackChunkName: "pieChart" */ "@/components/Charts/BarChart.vue");

import Enumerable from "linq";

export default {
  name: "VendorReportWidget",
  components: {
    PieChart,
    BarChart
  },
  props: {
    title: { type: String },
    data: {},
    chartType: { type: String, default: "PieChart" },
    hideLegend: { type: Boolean, default: false },
    singleColor: { type: Boolean, default: false },
    dataField: { type: String, default: "views" }
  },
  data() {
    return {
      reportMode: "chart"
    };
  },
  computed: {
    reportData() {
      if (!this.data) return undefined;
      return Enumerable.from(this.data)
        .select(i => i[this.dataField])
        .toArray();
    },
    reportLabels() {
      if (!this.data) return undefined;
      return Enumerable.from(this.data)
        .select(i => i["name"])
        .toArray();
    }
  }
};
</script>
