<template>
  <div id="vendor-editor">
    <template v-if="vendor">
      <b-row v-if="vendor.canEdit" class="mt-4">
        <b-col id="widgets" sm="8" lg="9" order="2" class="">
          <profile-score :vendor="vendor" />

          <basic-info-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />
          <logo-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <desc-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />
          <social-media-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />
          <videos-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <products-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <services-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <promos-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <testimonials-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
          />
          <contacts-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
            @sorted="$emit('edited', $event)"
            @sort-error="showError"
            @deleted="$emit('edited', $event)"
            @delete-error="showError"
          />
          <category-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />
          <keyword-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />
          <alias-path-editor
            @add-nav="addNavItem"
            :vendor="vendor"
            @saved="$emit('edited', $event)"
            @save-error="showError"
          />

          <div v-if="shouldOverscroll" class="overscroll"></div>
        </b-col>
        <b-col sm="4" lg="3" order="1">
          <vc-plug-in-nav
            :nav-items="navItems"
            @navigate-to="shouldOverscroll = true"
          />
        </b-col>
      </b-row>
      <h5 v-else class="mt-4 text-center text-muted">
        You do not have permissions to edit this vendor.
      </h5>
    </template>
  </div>
</template>

<script>
import ProfileScore from "./ProfileScore";
import BasicInfoEditor from "./BasicInfoEditor";
import DescEditor from "./DescEditor";
import SocialMediaEditor from "./SocialMediaEditor";
import LogoEditor from "./LogoEditor";
import VideosEditor from "./VideosEditor";
import ProductsEditor from "./ProductsEditor";
import ServicesEditor from "./ServicesEditor";
import PromosEditor from "./PromosEditor";
import TestimonialsEditor from "./TestimonialsEditor";
import ContactsEditor from "./ContactsEditor";
import CategoryEditor from "./CategoryEditor";
import KeywordEditor from "./KeywordEditor";
import AliasPathEditor from "./AliasPathEditor";

export default {
  name: "VendorEditor",
  components: {
    ProfileScore,
    BasicInfoEditor,
    DescEditor,
    SocialMediaEditor,
    LogoEditor,
    VideosEditor,
    ProductsEditor,
    ServicesEditor,
    PromosEditor,
    TestimonialsEditor,
    ContactsEditor,
    CategoryEditor,
    KeywordEditor,
    AliasPathEditor
  },
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      navItems: [],
      shouldOverscroll: false
    };
  },
  methods: {
    addNavItem(event) {
      if (this.navItems.find(i => i.href === event.href)) return;
      this.navItems.push(event);
    },
    showError(e) {
      this.$bvModal.msgBoxOk(e.message, {
        title: "Error",
        centered: true,
        noFade: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep legend {
  font-size: 0.9em;
  font-weight: bolder;
  letter-spacing: 0.3px;
}

div.overscroll {
  height: 60vh;
}

::v-deep .list-group-item {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

#widgets.clean ::v-deep {
  div.actions {
    opacity: 0;
  }
  .platter:hover div.actions {
    opacity: 1;
    transition: all 0.2s;
  }

  [data-editing] div.actions {
    opacity: 1;
  }
}

#widgets ::v-deep {
  h3 .badge {
    font-size: 0.4em;
  }
}
</style>