<template>
  <div id="basic" class="platter mb-4 p-3" :data-editing="editing">
    <div class="actions float-right">
      <b-button
        v-if="!editing"
        size="sm"
        variant="outline-primary"
        @click="editing = true"
        v-b-tooltip.hover.v-dark
        title="Edit"
      >
        <font-awesome-icon icon="pencil-alt" fixed-width />
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="save"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="cancel"
      >
        Cancel
      </b-button>
    </div>
    <h3 class="mb-3">Basic Information</h3>
    <b-row cols="1" class="p-1">
      <b-col v-if="this.$store.state.currentUser.systemAdmin">
        <b-form-group label="Active">
          <b-check switch :disabled="!editing" v-model="active" />
        </b-form-group>
      </b-col>
      <b-col>
        <vc-text-field-limit
          label="Name"
          v-model="name"
          @edit="editing = true"
          :editing="editing"
          :max-length="50"
        />
      </b-col>
      <b-col>
        <b-form-group label="Website URL">
          <b-input v-if="editing" v-model="websiteUrl" />
          <span v-if="!editing" @click="editing = true" class="url">
            {{ websiteUrl }}
            <em v-if="!websiteUrl">No website provided</em>
          </span>
          <a
            v-if="websiteUrl && !editing"
            :href="websiteUrl"
            target="_blank"
            class="ml-2"
            v-b-tooltip.hover.v-dark
            title="Open Website"
          >
            <font-awesome-icon icon="external-link-alt" />
          </a>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Profile URL">
          <span class="url">
            {{ profileUrl }}
          </span>
          <span v-if="!editing">
            <a
              :href="profileUrl"
              target="_blank"
              class="ml-2"
              v-b-tooltip.hover.v-dark
              title="Open Profile"
            >
              <font-awesome-icon icon="external-link-alt" />
            </a>
            &nbsp;
            <a
              href="#"
              class="ml-2"
              @click.prevent="copyProfileUrl"
              v-b-tooltip.hover.v-dark
              title="Copy Profile URL"
            >
              <font-awesome-icon icon="copy" />
            </a>
          </span>
          <small v-if="editing" class="text-muted d-block mt-1">
            To change your profile URL, please contact us.
          </small>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import isURL from "validator/es/lib/isURL";

export default {
  name: "BasicInfoEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      editing: false,
      name: "",
      websiteUrl: "",
      active: undefined
    };
  },
  computed: {
    profileUrl() {
      return (
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port && window.location.port !== "80"
          ? ":" + window.location.port
          : "") +
        "/vendor/" +
        this.vendor?.key
      );
    }
  },
  watch: {
    vendor: "updateLocalData"
  },
  mounted() {
    this.$emit("add-nav", {
      href: "#basic",
      title: "Basic Information"
    });

    this.updateLocalData();
  },
  methods: {
    updateLocalData() {
      this.name = this.vendor?.name;
      this.websiteUrl = this.vendor?.website;
      this.active = this.vendor?.active;
    },
    copyProfileUrl() {
      navigator.clipboard.writeText(this.profileUrl).then(() => {
        this.$bvToast.toast("Profile URL Copied to Clipboard", {
          solid: true,
          headerClass: "d-none",
          variant: "dark"
        });
      });
    },
    save() {
      if (!this.name?.trim()) {
        this.$emit("save-error", {
          message: "You must provide a name."
        });
        return;
      }

      if (!this.isWebsiteUrlValid()) {
        this.$emit("save-error", {
          message:
            "The website url provided is not valid. Please provide a valid url."
        });
        return;
      }

      const data = {
        vendorKey: this.vendor?.key,
        name: this.name?.trim(),
        website: this.websiteUrl?.trim(),
        active: this.active
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.$emit("saved", d);
          this.editing = false;
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    cancel() {
      this.updateLocalData();
      this.editing = false;
    },
    isWebsiteUrlValid() {
      if (!this.websiteUrl) return true;

      const urlOptions = {
        require_protocol: true
      };

      let url = this.websiteUrl;

      let isValid = isURL(url, urlOptions);

      if (isValid) return true;

      url = "http://" + url;

      isValid = isURL(url, urlOptions);

      if (!isValid) return false;

      this.websiteUrl = url;
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
span.url {
  word-wrap: break-word;
}
</style>