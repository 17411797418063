<template>
  <div v-if="show" id="promos" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        :title="'Add ' + itemName"
        @click="add"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
    </div>
    <h3 class="mb-3">{{ itemsName }}</h3>
    <vc-editor-table
      :items="items"
      :item-name="itemName"
      featurable
      @edit="edit"
      @add="add"
      @sort="sort"
      @feature="feature"
      @delete="deleteItem"
    />

    <b-modal
      id="promo-item-modal"
      :title="modalModeLabel"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      no-fade
      centered
      @ok="save"
    >
      <vc-text-field-limit
        :label="itemName + ' Name'"
        v-model="modalName"
        :editing="true"
        :max-length="75"
        always-show-remaining
        required
      />

      <vc-text-field-limit
        label="Description"
        v-model="modalDesc"
        :editing="true"
        :max-length="175"
        :rows="3"
        :max-rows="4"
        always-show-remaining
        required
      />

      <b-form-group>
        <b-check v-model="modalFeatured" switch>
          Featured {{ itemName }}
        </b-check>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import Enumerable from "linq";

export default {
  name: "PromosEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      selectedItem: undefined,
      modalName: undefined,
      modalDesc: undefined,
      modalFeatured: undefined
    };
  },
  computed: {
    itemName() {
      return "Promotion";
    },
    itemsName() {
      return `${this.itemName}s`;
    },
    modalModeLabel() {
      return `${!this.item ? "Add" : "Edit"} ${this.itemName}`;
    },
    items() {
      return Enumerable.from(this.vendor?.promos)
        .select(i => {
          return {
            id: i.id,
            title: i.title,
            desc: i.copy,
            featured: i.isFeatured,
            item: i
          };
        })
        .toArray();
    },
    selectedItemIndex() {
      return this.items.findIndex(i => i.item === this.selectedItem);
    }
  },
  watch: {
    selectedItem() {
      this.modalName = this.selectedItem?.title;
      this.modalDesc = this.selectedItem?.copy;
      this.modalFeatured = this.selectedItem?.isFeatured;
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#promos",
        title: this.itemsName
      });
    }
  },
  methods: {
    add() {
      this.selectedItem = undefined;
      this.$bvModal.show("promo-item-modal");
    },
    edit(i) {
      this.selectedItem = i.item;
      this.$bvModal.show("promo-item-modal");
    },
    sort(d) {
      const data = {
        promosSort: d
      };
      this.pushChange(
        data,
        d => {
          this.$emit("sorted", d);
        },
        e => {
          this.$emit("sort-error", e);
        }
      );
    },
    save(e) {
      if (!this.modalName?.trim()) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a promo name."
        });
        return;
      }

      if (!this.modalDesc?.trim()) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide a promo description."
        });
        return;
      }

      const data = {
        promo: {
          title: this.modalName?.trim(),
          copy: this.modalDesc?.trim(),
          featured: this.modalFeatured
        },
        promoIndex: this.selectedItemIndex
      };

      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.modalName = this.modalDesc = this.modalFeatured = undefined;
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    feature(i) {
      const data = {
        promo: {
          title: i.item.title,
          copy: i.item.copy,
          featured: !i.item.isFeatured
        },
        promoIndex: this.items.findIndex(j => j.item === i.item)
      };

      this.pushChange(
        data,
        d => {
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    deleteItem(i) {
      this.selectedItem = i.item;

      const data = {
        promoDelete: this.selectedItemIndex
      };
      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.$emit("deleted", d);
        },
        e => {
          this.$emit("delete-error", e);
        }
      );
    },
    pushChange(data, s, e) {
      this.$store
        .dispatch("pushVendorChanges", { vendorKey: this.vendor?.key, ...data })
        .then(s, e);
    }
  }
};
</script>