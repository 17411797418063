<template>
  <div v-if="show" id="products" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        :title="'Add ' + itemName"
        @click="add"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
    </div>
    <h3 class="mb-3">{{ itemsName }}</h3>
    <vc-editor-table
      :items="items"
      :item-name="itemName"
      @edit="edit"
      @add="add"
      @delete="deleteItem"
      @sort="sort"
    />

    <b-modal
      id="product-item-modal"
      :title="modalModeLabel"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      no-fade
      centered
      @ok="save"
    >
      <image-field
        :src="modalThumbSrc"
        @remove="modalThumbSrc = undefined"
        @uploaded="modalThumbSrc = $event.webUrl"
        kind="product"
        :vendor-key="vendor.key"
      />

      <vc-text-field-limit
        label="Product Name"
        v-model="modalProductName"
        :editing="true"
        :max-length="50"
        always-show-remaining
      />

      <vc-text-field-limit
        label="Description"
        v-model="modalDesc"
        :editing="true"
        :max-length="400"
        :rows="4"
        :max-rows="7"
        always-show-remaining
      />
    </b-modal>
  </div>
</template>

<script>
import Enumerable from "linq";
import ImageField from "./ImageField.vue";

export default {
  name: "ProductsEditor",
  components: { ImageField },
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      selectedItem: undefined,
      modalThumbSrc: undefined,
      modalProductName: undefined,
      modalDesc: undefined
    };
  },
  computed: {
    itemName() {
      return "Product";
    },
    itemsName() {
      return `${this.itemName}s`;
    },
    items() {
      return Enumerable.from(this.vendor?.products)
        .select(i => {
          return {
            title: i.title,
            desc: i.copy,
            thumbSrc: i.imageSrc,
            item: i
          };
        })
        .toArray();
    },
    modalModeLabel() {
      return `${!this.selectedItem ? "Add" : "Edit"} ${this.itemName}`;
    },
    selectedItemIndex() {
      return this.items.findIndex(i => i.item === this.selectedItem);
    }
  },
  watch: {
    selectedItem() {
      this.modalThumbSrc = this.selectedItem?.imageSrc;
      this.modalProductName = this.selectedItem?.title;
      this.modalDesc = this.selectedItem?.copy;
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#products",
        title: this.itemsName
      });
    }
  },
  methods: {
    add() {
      this.selectedItem = undefined;
      this.modalThumbSrc = this.modalProductName = this.modalDesc = undefined;
      this.$bvModal.show("product-item-modal");
    },
    edit(i) {
      this.selectedItem = i.item;
      this.$bvModal.show("product-item-modal");
    },
    sort(d) {
      const data = {
        productsSort: d
      };
      this.pushChange(
        data,
        d => {
          this.$emit("sorted", d);
        },
        e => {
          this.$emit("sort-error", e);
        }
      );
    },
    save(e) {
      if (
        !this.modalProductName?.trim() &&
        !this.modalThumbSrc &&
        !this.modalDesc.trim()
      ) {
        e.preventDefault();
        this.$emit("save-error", {
          message: "Please provide an image, product name or description."
        });
        return;
      }

      const data = {
        product: {
          imageSrc: this.modalThumbSrc,
          title: this.modalProductName?.trim(),
          copy: this.modalDesc?.trim()
        },
        productIndex: this.selectedItemIndex
      };

      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.modalThumbSrc = this.modalProductName = this.modalDesc = undefined;
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    deleteItem(i) {
      this.selectedItem = i.item;

      const data = {
        productDelete: this.selectedItemIndex
      };
      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.$emit("deleted", d);
        },
        e => {
          this.$emit("delete-error", e);
        }
      );
    },
    pushChange(data, s, e) {
      this.$store
        .dispatch("pushVendorChanges", { vendorKey: this.vendor?.key, ...data })
        .then(s, e);
    }
  }
};
</script>