import urlParser from "js-video-url-parser";
import { getJson } from "@/utils/dataTransfer";
import Enumearble from "linq";

const getVideoSnippets = (urls) => {
    return new Promise((resolve) => {
        const ids = Enumearble.from(urls).select(i => urlParser.parse(i)?.id).where(i => i).toArray();

        const apiKey = process.env.VUE_APP_YOUTUBE_API_KEY;
        getJson(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${ids.join(",")}&key=${apiKey}`
        ).then(d => {
          const videoItems = Enumearble.from(d.items).select(i => {
            let thumb = i.snippet?.thumbnails?.default?.url;

            if (i.snippet?.thumbnails?.standard)
              thumb = i.snippet?.thumbnails?.standard.url;

              return {
                id: i.id,
                thumbnailUrl: thumb,
                title: i.snippet?.title
              }
          }).toArray();

        resolve(!videoItems?.length ? undefined : videoItems);
        });
    });    
}

export { getVideoSnippets }